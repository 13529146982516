import "./App.css";
import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  YAxis as YAxis2,
} from "recharts";

function useLoadData(csvFilePath) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetch(csvFilePath)
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          complete: (result) => {
            const filteredData = result.data.filter(
              (row) => row.length === 3 && !isNaN(parseFloat(row[2]))
            );
            setData(filteredData);
          },
          header: false,
        });
      });
  }, [csvFilePath]);

  return data;
}

function findExtremes(data, windowLen) {
  const result = [];
  const lowDeque = [];
  const highDeque = [];

  for (let i = 0; i < data.length; i++) {
    const currentPrice = parseFloat(data[i][2]);

    // Remove elements from the front of the deque that are outside the current window
    while (lowDeque.length > 0 && lowDeque[0][0] <= i - windowLen) {
      lowDeque.shift();
    }
    while (highDeque.length > 0 && highDeque[0][0] <= i - windowLen) {
      highDeque.shift();
    }

    // Remove elements from the back of the deque that are greater than the current price
    while (
      lowDeque.length > 0 &&
      lowDeque[lowDeque.length - 1][1] > currentPrice
    ) {
      lowDeque.pop();
    }
    // Remove elements from the back of the deque that are less than the current price
    while (
      highDeque.length > 0 &&
      highDeque[highDeque.length - 1][1] < currentPrice
    ) {
      highDeque.pop();
    }

    // Add the current element to the back of the deque
    lowDeque.push([i, currentPrice]);
    highDeque.push([i, currentPrice]);

    // Add the minimum and maximum prices to the result array for windows of length windowLen or greater
    if (i >= windowLen - 1) {
      const dateStr = data[i][0];
      const timestamp = data[i][1];
      const lowestPrice = lowDeque[0][1];
      const highestPrice = highDeque[0][1];
      result.push({
        date: dateStr,
        timestamp,
        lowest: lowestPrice,
        highest: highestPrice,
      });
    }
  }

  return result;
}

function calculateVolatility(data, window) {
  const volatilityData = [];

  for (let i = window; i < data.length; i++) {
    const windowData = data.slice(i - window, i);
    const returns = windowData.map((item, index) => {
      if (index === 0) return 0;
      const prevPrice = parseFloat(windowData[index - 1][2]);
      const currentPrice = parseFloat(item[2]);
      return (currentPrice - prevPrice) / prevPrice;
    });

    const avgReturn = returns.reduce((sum, val) => sum + val, 0) / (window - 1);
    const variance =
      returns.reduce((sum, val) => sum + Math.pow(val - avgReturn, 2), 0) /
      (window - 1);
    const stdDev = Math.sqrt(variance);
    const annualizedVolatility = stdDev * Math.sqrt(365);

    volatilityData.push({ date: data[i][0], value: annualizedVolatility });
  }

  return volatilityData;
}

function EtherChart({ data1, data2Lowest, data2Highest }) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 150,
  });
  const [line1Visible, setLine1Visible] = useState(true);
  const [line2Visible, setLine2Visible] = useState(true);
  const [line3Visible, setLine3Visible] = useState(true);
  const [line4Visible, setLine4Visible] = useState(true);
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const [isRatioLogarithmic, setIsRatioLogarithmic] = useState(true);
  const [volatilityWindow, setVolatilityWindow] = useState(30);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight - 150,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const truncatedData1 = data1.slice(
    -Math.min(data2Lowest.length, data2Highest.length)
  );

  const formattedData1 = truncatedData1.map((item) => ({
    date: item[0],
    value1: parseFloat(item[2]),
  }));

  const formattedData2Lowest = data2Lowest.map((item) => ({
    date: item.date,
    lowest: item.lowest,
  }));

  const formattedData2Highest = data2Highest.map((item) => ({
    date: item.date,
    highest: item.highest,
  }));

  const volatilityData = calculateVolatility(data1, volatilityWindow);
  const truncatedVolatilityData = volatilityData.slice(
    -Math.min(data2Lowest.length, data2Highest.length)
  );

  const formattedVolatilityData = truncatedVolatilityData.map((item) => ({
    date: item.date,
    volatility: item.value,
  }));

  const mergedData = formattedData1.map((item, index) => ({
    date: item.date,
    value1: item.value1,
    lowest: formattedData2Lowest[index]
      ? formattedData2Lowest[index].lowest
      : null,
    highest: formattedData2Highest[index]
      ? formattedData2Highest[index].highest
      : null,
    volatility: formattedVolatilityData[index]
      ? formattedVolatilityData[index].volatility
      : null,
  }));

  const allPositiveValues = mergedData.every(
    (item) => item.value1 > 0 && (item.lowest === null || item.lowest > 0)
  );

  return (
    <>
      <div>
        <label>
          <input
            type="checkbox"
            checked={line1Visible}
            onChange={(event) => setLine1Visible(event.target.checked)}
          />
          Live Price
        </label>
        <label>
          <input
            type="checkbox"
            checked={line2Visible}
            onChange={(event) => setLine2Visible(event.target.checked)}
          />
          Lowest Price
        </label>
        <label>
          <input
            type="checkbox"
            checked={line3Visible}
            onChange={(event) => setLine3Visible(event.target.checked)}
          />
          Highest Price
        </label>
        <label>
          <input
            type="checkbox"
            checked={line4Visible}
            onChange={(event) => setLine4Visible(event.target.checked)}
          />
          Volatility
        </label>
        <label>
          <input
            type="checkbox"
            checked={isLogarithmic}
            onChange={(event) => setIsLogarithmic(event.target.checked)}
            disabled={!allPositiveValues}
          />
          Logarithmic Prices
        </label>
        <label>
          <input
            type="checkbox"
            checked={isRatioLogarithmic}
            onChange={(event) => setIsRatioLogarithmic(event.target.checked)}
          />
          Logarithmic Ratio
        </label>
        <label>
          Volatility Window (days):
          <input
            type="number"
            value={volatilityWindow}
            onChange={(event) =>
              setVolatilityWindow(parseInt(event.target.value))
            }
          />
        </label>
      </div>
      <LineChart
        width={dimensions.width}
        height={dimensions.height}
        data={mergedData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          yAxisId="left"
          scale={isLogarithmic && allPositiveValues ? "log" : "linear"}
          domain={
            isLogarithmic && allPositiveValues
              ? ["auto", "auto"]
              : ["dataMin", "dataMax"]
          }
        />
        <YAxis2
          yAxisId="right"
          orientation="right"
          scale={isRatioLogarithmic ? "log" : "linear"}
          domain={
            isRatioLogarithmic ? ["auto", "auto"] : ["dataMin", "dataMax"]
          }
        />
        <Tooltip />
        <Legend />
        {line1Visible && (
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="value1"
            stroke="#8884d8"
            name="Live Price"
            dot={false}
          />
        )}
        {line2Visible && (
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="lowest"
            stroke="#82ca9d"
            name="Lowest Price"
            dot={false}
          />
        )}
        {line3Visible && (
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="highest"
            stroke="#ff7300"
            name="Highest Price"
            dot={false}
          />
        )}
        {line4Visible && (
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="volatility"
            stroke="#ff0000"
            name="Volatility"
            dot={false}
          />
        )}
      </LineChart>
    </>
  );
}

function App() {
  const data1 = useLoadData("/historic_data.csv");
  const [optimalWindowSizeLowest, setOptimalWindowSizeLowest] = useState(null);
  const [optimalWindowSizeHighest, setOptimalWindowSizeHighest] =
    useState(null);
  const [userWindowSizeLowest, setUserWindowSizeLowest] = useState(null);
  const [userWindowSizeHighest, setUserWindowSizeHighest] = useState(null);

  useEffect(() => {
    if (data1.length > 0) {
      const optimalLowest = findOptimalWindowSize(data1, isNonDecreasingLowest);
      const optimalHighest = findOptimalWindowSize(
        data1,
        isNonDecreasingHighest
      );
      setOptimalWindowSizeLowest(optimalLowest);
      setOptimalWindowSizeHighest(optimalHighest);
      setUserWindowSizeLowest(optimalLowest);
      setUserWindowSizeHighest(optimalHighest);
    }
  }, [data1]);

  const data2Lowest = userWindowSizeLowest
    ? findExtremes(data1, userWindowSizeLowest)
    : [];
  const data2Highest = userWindowSizeHighest
    ? findExtremes(data1, userWindowSizeHighest)
    : [];
  console.log(data2Lowest, data2Highest);

  const lastValue1 = data1.length
    ? parseFloat(data1[data1.length - 1][2]) || "N/A"
    : "Loading...";
  const lastLowest = data2Lowest.length
    ? parseFloat(data2Lowest[data2Lowest.length - 1].lowest) || "N/A"
    : "Loading...";
  const lastHighest = data2Highest.length
    ? parseFloat(data2Highest[data2Highest.length - 1].highest) || "N/A"
    : "Loading...";
  const lastDate1 = data1.length
    ? data1[data1.length - 1][0] || "N/A"
    : "Loading...";

  const handleWindowSizeChangeLowest = (event) => {
    setUserWindowSizeLowest(parseInt(event.target.value));
  };

  const handleWindowSizeChangeHighest = (event) => {
    setUserWindowSizeHighest(parseInt(event.target.value));
  };

  return (
    <div className="App">
      <h3>
        Ether price on {lastDate1}: ${lastValue1}
      </h3>
      <h3>Latest lowest price: ${lastLowest}</h3>
      <h3>Latest highest price: ${lastHighest}</h3>
      <div>
        <label>
          Optimal Window Size (days) for non-decreasing lowest:{" "}
          {optimalWindowSizeLowest}
        </label>
        <br />
        <label>
          User Window Size (days) for non-decreasing lowest:
          <input
            type="number"
            value={userWindowSizeLowest || ""}
            onChange={handleWindowSizeChangeLowest}
          />
        </label>
        <br />
        <label>
          Optimal Window Size (days) for non-decreasing highest:{" "}
          {optimalWindowSizeHighest}
        </label>
        <br />
        <label>
          User Window Size (days) for non-decreasing highest:
          <input
            type="number"
            value={userWindowSizeHighest || ""}
            onChange={handleWindowSizeChangeHighest}
          />
        </label>
      </div>
      <EtherChart
        data1={data1}
        data2Lowest={data2Lowest}
        data2Highest={data2Highest}
      />
    </div>
  );
}

function findOptimalWindowSize(data, isNonDecreasing) {
  let left = 1;
  let right = data.length;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const extremes = findExtremes(data, mid);

    if (isNonDecreasing(extremes)) {
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  return left;
}

function isNonDecreasingLowest(data) {
  for (let i = 1; i < data.length; i++) {
    if (data[i].lowest < data[i - 1].lowest) {
      return false;
    }
  }
  return true;
}

function isNonDecreasingHighest(data) {
  for (let i = 1; i < data.length; i++) {
    if (data[i].highest < data[i - 1].highest) {
      return false;
    }
  }
  return true;
}

export default App;
